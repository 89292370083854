import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getZammadUrl } from "../settings";
import { useAuthenticated } from "react-admin";

const useStyles = makeStyles(theme => ({
    invisible: { visibility: "hidden" }
}));

/**
 * A background request to /auth/sso starts:
 *  - browser sends HTTP get with ASB SESSION Cookie to /auth/sso
 *  - nginx server intercepts the GET call and validates the cookie against ASB
 *  - ASB username (email) is returned and forwarded to Zammad
 *  - Zammad creates a new session and returns a new cookie
 *
 * Eventually user clicks on the button in Challenge page and gets sent to an already logged in Zammad
 */
export function LoginIntoZammad({ onLoad = undefined }) {
    const styles = useStyles();

    // redirects to login if not authenticated
    useAuthenticated();

    //cleanup unwanted attachments from zammad
    cleanUpHarmfulAttachments()
            .catch(err => console.error('Cannot fetch open tickets', err))
            .then(value =>
                    console.log("cleanup successful.")
            );

    return <iframe title="Zammad Background Login"
                   className={styles.invisible}
                   src={"https:" + getZammadUrl() + '/auth/sso'}
                   onLoad={onLoad}
    />
}

async function cleanUpHarmfulAttachments() {
    const url = `/anna/api/v1/challenge/cleanup`;
    try {
        const { data } = await (await fetch(url)).json();
        return data || [];
    } catch (error) {
        console.error('Error whiling deleting the attachments.', error);
        return null;
    }
}
