import React from 'react'
import { getZammadUrl } from "../settings";
import { Loading } from "react-admin";
import { LoginIntoZammad } from "./LoginIntoZammad";

/**
 * User come to this page from notification emails by Zammad.
 * We do SSO login in the background and redirect to the Zammad page after the login is verified.
 */
export function RedirectToZammad({ url }: { url: string }) {

    function loginCompleted() {
        if (url.startsWith("/")) {
            /*
               - Zammad sends email with URLs without the leading `/` prefix after `#`
               - React-Router adds it
               - <Route/> part matches it with leading slash and runs this code
               - We cut it out and redirect
            */
            url = url.substring(1)
        }

        // Replace current window because we came here by email link, not from the App itself
        window.location.href = getZammadUrl() + '#' + url;
    }

    return <>
        <Loading loadingPrimary="resources.challenge.loadingPrimary"/>
        <LoginIntoZammad onLoad={loginCompleted}/>
    </>

}