import React, { FC, useEffect, useState } from 'react';
import {
    Datagrid,
    FieldProps,
    Filter,
    List,
    Pagination,
    SelectInput,
    TextField,
    TextInput,
    useDataProvider,
    useMutation,
    useNotify,
    useRecordContext,
    useRefresh
} from 'react-admin';
import { getAssigneesFromDb } from "../tickets/AssigneeNNaSelection";
import { Tooltip } from '@material-ui/core';



const Toggle = ({ source }) => {
    const record = useRecordContext();
    const [mutate, { loading }] = useMutation();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleChange = async (event) => {
        if (record) {
            await mutate({
                    type: 'update',
                    resource: 'ticketadmin',
                    payload: { id: record.id, data: { ...record, [source]: event.target.checked }, previousData: record }
                },
                {
                    onSuccess: ({ data }) => {
                        notify('Changes saved', 'info');
                        refresh();
                    },
                    onFailure: (error) => {
                        notify(`Error: could not save changes. ${error.message}`, 'warning');
                    }
                });
        }
    };

    return record ? (
        <input
            type="checkbox"
            checked={record[source]}
            onChange={handleChange}
            disabled={loading}
        />
    ) : null;
};

// interface TicketAdminFilterProps {
//     defaultAssignees: string[]; // Adjust the type as necessary, e.g., array of strings
// }
interface Assignee {
    customerid: string;
    nnacd: string;
}

interface TicketAdminFilterProps {
    defaultAssignees: Assignee[];
}

const TicketAdminFilter: React.FC<React.PropsWithChildren<TicketAdminFilterProps>> = (props) => {
    const { defaultAssignees, children } = props;

    let choices = defaultAssignees.map(assignee => ({
        id: assignee.customerid,
        name: assignee.nnacd
    }));

// Sort by name
    choices.sort((a, b) => a.name.localeCompare(b.name));

// Remove duplicates by id
    const uniqueIds = new Set();
    const distinctChoices = choices.filter(choice => {
        if (!uniqueIds.has(choice.id)) {
            uniqueIds.add(choice.id);
            return true;
        }
        return false;
    });

    return (

        <Filter {...props}>
            <TextInput label="Search by user name" source="userName" alwaysOn/>
            <SelectInput
                label="Select customer"
                source="customer_id"
                choices={distinctChoices}
                alwaysOn
            />
        </Filter>
    );
};


export const TicketAdmin: React.FC = (props) => {

    const dataProvider = useDataProvider();
    const [defaultAssignees, setDefaultAssignees] = useState([]);

    useEffect(() => {
        const fetchAssignees = async () => {
            try {
                const response = await getAssigneesFromDb(dataProvider);
                setDefaultAssignees(response);
            } catch (error) {
                console.info("error in useeffect dataProvider go data: " + error);
                console.error(error);
            }
        };

        fetchAssignees();
    }, [dataProvider]);


    // const TooltipField: FC<FieldProps & { tooltipText: string }> = ({ tooltipText, ...props }) => {
    //     return (
    //         <Tooltip title={tooltipText}>
    //         <span>
    //             <TextField {...props} />
    //         </span>
    //         </Tooltip>
    //     );
    // };
    //
    // const TooltipToggleField: FC<FieldProps & { tooltipText: string }> = ({ tooltipText, ...props }) => {
    //     return (
    //         <Tooltip title={tooltipText}>
    //         <span>
    //             <Toggle {...props} />
    //         </span>
    //         </Tooltip>
    //     );
    // };


    return (<List {...props} bulkActionButtons={false} filters={<TicketAdminFilter defaultAssignees={defaultAssignees}/>} perPage={25} actions={false}
                  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>}>
            <Datagrid>
                {/*<TextField source="userId" />*/}
                <TextField source="userName"/>
                <Toggle source="active"/>
                <Toggle source="notification"/>
                <Toggle source="onlyAssignedToMe"/>
                <Toggle source="closedTickets"/>
                <Toggle source="newTickets"/>
                <Toggle source="updatedTickets"/>
                {/* */}
            </Datagrid>
        </List>
    )
}
// export const TicketAdmin = (props: any) => (
//
//     return (    <List {...props} bulkActionButtons={false} filters={<TicketAdminFilter />} perPage={25} actions={false} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}>
//             <Datagrid>
//                 {/*<TextField source="userId" />*/}
//                 <TextField source="userName" />
//                 <Toggle source="notification" />
//                 <Toggle source="onlyAssignedToMe" />
//                 <Toggle source="closedTickets" />
//                 <Toggle source="newTickets" />
//                 <Toggle source="updatedTickets" />
//                 {/* */}
//             </Datagrid>
//         </List>
//     )
//
//
// );
