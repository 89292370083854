import ChallengeCreate from './ChallengeCreate';
import { ChallengeList } from "./ChallengeList";

export default {
    edit: ChallengeCreate,
    list: ChallengeList,
    options: {
        label: "Challenges"
    }
};
