import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslate } from "react-admin";
import ChallengeState from "../model/ChallengeState";
import ReactTimeAgo from 'react-time-ago'
import { getZammadUrl } from '../settings';

const daysToShowClosedTickets = 30 * 6;

const useStyles = makeStyles({
    alertBox: {
        marginBottom: "1em",
    },
});
function ShowSingleTicket({ ticket }) {
    const createDate = new Date(Date.parse(ticket.date_created))

    let title = ticket.note
    if (!title || title.length < 10) {
        title = ticket.title;
    } else if (title.length > 100) {
        title = title.substring(0, 97) + "..."
    }

    return <>
        <Link target="_blank" href={'/#/tickets/' + ticket.id}>{title}</Link>,&nbsp;
        <ReactTimeAgo date={createDate} locale="en-US" timeStyle="round"/>
    </>
}

function ShowTicketsAsList({ tickets }) {
    return <ul>
        {tickets.map((ticket) => {
            return (
                <li key={ticket.id}><ShowSingleTicket ticket={ticket}/></li>
            );
        })}
    </ul>;
}
function isOld(createdAt: string): boolean {
    const today = new Date();
    const createDate = new Date(Date.parse(createdAt))
    const diffDays = (today.getTime() - createDate.getTime()) / 86400000;
    return diffDays > daysToShowClosedTickets
}
function ListTickets({ tickets, openTickets }) {

    const translate = useTranslate();
    const classes = useStyles();

    const ticketMessage = openTickets ? "resources.challenge.openTicketExistsMessage" : "resources.challenge.closedTicketExistsMessage";

    return <Alert className={classes.alertBox}
                  severity={openTickets ? "warning" : "info"}>
        {translate(ticketMessage, { smart_count: tickets.length })}
        {tickets.length === 1
            ? <p><ShowSingleTicket ticket={tickets[0]}/></p>
            : <ShowTicketsAsList tickets={tickets}/>}
    </Alert>
}
async function loadTickets(isin) {
    if (!isin || isin.length === 0)
        return null

    const url = `/anna/api/v1/isin/${isin}/tickets`;
    try {
        const { data } = await (await fetch(url)).json();
        return data || [];
    } catch (error) {
        console.error('Cannot fetch open tickets', error);
        return null;
    }
}
function ChallengeShortList({ isin, type }) {
    const openTickets: boolean = type === "open";

    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        console.debug("Loading challenges for ", isin)

        loadTickets(isin)
            .catch(err => console.error('Cannot fetch open tickets', err))
            .then(value =>
                setTickets(value)
            );

    }, [isin]);

    if (!tickets || tickets.length === 0)
        return null

    const filteredTickets = openTickets
        ? tickets.filter(t => t.status !== 'CLOSED')
        : tickets.filter(t => t.status === 'CLOSED' && !isOld(t.date_created));

    if (filteredTickets && filteredTickets.length > 0) {
        return <ListTickets tickets={filteredTickets} openTickets={openTickets}/>;
    } else {
        return null;
    }
}

export default ChallengeShortList;
