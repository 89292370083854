const KEY_APP_SETTINGS = 'ANNA_settings';
const SETTINGS_URL = '/anna/public-settings';
const KEY_ZAMMAD_URL = 'zammad_url';

export const SETTINGS_LOADED_EVENT = 'SETTINGS_LOADED_EVENT';

export const getZammadUrl = () => {
    return `//${getSettingValue(KEY_ZAMMAD_URL)}`;
}

const getSettingValue = (what: string) => {
    const settings = getSettings();
    if (!settings) {
        return null;
    }
    return settings[what];
}
/**
 * Retrieve the public settings and saves them into localstorage
 */
export const loadSettings = async () => {
    const settings = await (await fetch(SETTINGS_URL)).json();
    localStorage.setItem(KEY_APP_SETTINGS, JSON.stringify(settings));

    // Once the settings are loaded, fire SETTINGS_LOADED_EVENT such that subscribers can continue their work 
    const loadedEvent = new Event(SETTINGS_LOADED_EVENT);
    document.dispatchEvent(loadedEvent);
}

/**
 * Parse the settings from localstorage
 */
const getSettings = () => {
    return JSON.parse(localStorage.getItem(KEY_APP_SETTINGS));
}