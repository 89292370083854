import React from "react";
import { useTranslate } from 'ra-core';
import { Button, Card, CardActionArea, CardContent, Link, Toolbar } from "@material-ui/core";
import { getZammadUrl } from '../settings';
import { LoginIntoZammad } from "./LoginIntoZammad";

export function ChallengeList() {
    const translate = useTranslate();

    return <>
        <Card>
            <CardContent>
                Data challenges are available here
            </CardContent>
            <CardActionArea>
                <Toolbar>
                    <Button color="primary"
                            component={Link}
                            target="_blank"
                            href={getZammadUrl() + '#ticket/view/my_tickets'}>
                        {translate('resources.challenge.openTickets')}
                    </Button>
                </Toolbar>
            </CardActionArea>
        </Card>

        <LoginIntoZammad/>
    </>
}
